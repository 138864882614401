import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';
import { colors, media } from '../css';
import './all.scss';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import Footer from './Footer';
import MobileNav from './MobileNav';

const Container = styled.div`
  position: relative;
  padding: 30px 0 30px;
`;

const ContentContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  margin: 100px auto;
  box-sizing: border-box;

  ${media.tablet`margin: 30px auto;`}
  ${media.phone`margin: 0px auto;`}
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutHeadingQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "start-page" } }) {
          frontmatter {
            metadata {
              title
              description
            }
            top {
              background {
                childImageSharp {
                  fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <PageWrapper>
        <Helmet>
          <html lang="en" />
          <title>{data.markdownRemark.frontmatter.metadata.title}</title>
          <meta name="description" content={data.markdownRemark.frontmatter.metadata.description} />
          <meta name="google-site-verification" content="tzyyHALStCS_REOz97iNXP_KOlZfnwe9Hw3QJkefvUY" />

          <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
          <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="website" />
          <meta property="og:title" content={data.markdownRemark.frontmatter.metadata.title} />
          <meta property="og:url" content="/" />
        </Helmet>
        <Container>
          <PreviewCompatibleImage
            image={data.markdownRemark.frontmatter.top.background}
            alt="Grå bakgrund"
            backgroundColor={colors.grey}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: -1
            }}
          />
          <MobileNav />
          <Logo />
          <Navbar />
        </Container>
        <ContentContainer>{children}</ContentContainer>
        <Footer background={data.markdownRemark.frontmatter.top.background} />
      </PageWrapper>
    )}
  />
);

export default TemplateWrapper;
