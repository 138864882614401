import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade';
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ArticleTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const ArticleContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <ArticleContent content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

ArticleTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Article = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Fade bottom duration={500} distance="30px">
        <ArticleTemplate
          content={post.html}
          contentComponent={HTMLContent}
          helmet={
            <Helmet
              titleTemplate="%s"
            >
              <title>{`${post.frontmatter.metadata.title}`}</title>
              <meta name="description" content={`${post.frontmatter.metadata.description}`} />
            </Helmet>
          }
          title={post.frontmatter.title}
        />
      </Fade>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Article

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metadata {
          title
          description
        }
      }
    }
  }
`
